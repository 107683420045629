import React, { Component } from "react";
import Graph from "./Graph";
 
class NodeComponent extends Component {

  constructor(props){
    super(props);
    this.state = {counter:0};
    
  }

  componentDidMount(){
    this.setState(this.state);

  }


  render() {
    return (
      <div>
        <div className="textBox">
          <p>{this.props.textDescription}</p>
        </div>
        <Graph fixed = {this.props.fixed} counter={this.props.counter} nodes={this.props.nodes} prevNodes = {this.props.prevNodes} links={this.props.links} foci={this.props.foci} prevFoci= {this.props.prevFoci} callBack={this.props.callBackNodes} collectHistory = {this.props.collectHistory} categories = {(this.props.categories? this.props.categories:[])}/>
      </div>
    );
  }
}
 
export default NodeComponent;
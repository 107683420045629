import React, { Component } from "react";
import Graph from "./Graph";
 
class NodeButtonComponent extends Component {

  constructor(props){
    super(props);
    this.state = {counter:0};
    this.input = React.createRef();
    this.counter = 0;
    
  }

  componentDidMount(){
    this.setState(this.state);

  }


  render() {
    
    return (
      <div>
        <div className="textBox">
          <p>{this.props.textDescription}</p>
        </div>
        <Graph counter={this.props.counter} nodes={this.props.nodes} prevNodes = {this.props.prevNodes} links={this.props.links} foci={this.props.foci} prevFoci= {this.props.prevFoci} callBack={this.props.callBackNodes} collectHistory = {this.props.collectHistory}/>
        <div className="container" id="userInputStd">
              <input id="usr" type="text" placeholder="Name" ref={this.input}/>
              <button id="confirm" onClick={() => (this.props.callBackButton(this.input.current.value))}>Confirm</button>
        </div>
      </div>
    );
  }
}
 
export default NodeButtonComponent;
import React, { Component } from "react";
import Graph from "./Graph";
 
class NodeCategoriesComponent extends Component {

  constructor(props){
    super(props);
    this.state = {counter:0};
    this.counter = 0;
    
  }

  componentDidMount(){
    this.setState(this.state);

  }

  updateCounter = (id, text) => {
    this.props.callBackButton(this.props.counter, id, text);
}


  render() {
    
    return (
      <div>
        <div className="textBox">
          <p>{this.props.textDescription}</p>
        </div>
        <Graph counter={this.props.counter} nodes={this.props.nodes} prevNodes = {this.props.prevNodes} links={this.props.links} foci={this.props.foci} prevFoci= {this.props.prevFoci} callBack={this.props.callBackNodes} collectHistory = {this.props.collectHistory}/>
        <div className="container" id="userInputStd">
          {this.props.categories.map( (category, i) => (
              <button className="categoryButton"  key = {category.key} type="button" style={{background:category.color}} value={category.text} onClick={() => this.updateCounter(category.key,category.text)}>{category.text}</button>
          ))}
        </div>
      </div>
    );
  }
}
 
export default NodeCategoriesComponent;
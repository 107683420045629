import React, { Component } from "react";
import Graph from "./Graph";
 
class NodeSliderComponent extends Component {

  constructor(props){
    super(props);
    this.state = {counter:0,
                 value:1};

    this.value = 1;
    
  }

  componentDidMount(){
    let nodes = JSON.parse(JSON.stringify(this.props.nodes));
    if(this.props.counter < nodes.length){
      let updateValue = JSON.parse(JSON.stringify(this.props.sliderUpdate));
      this.setState({value:updateValue});
    } else {
      this.setState(this.state);
    }

  }

  componentDidUpdate(){
    
  }

  updateSlider = (event) => {
    this.setState({value:event.target.value});
  }

  updateCounter = (event) => {
      this.props.callBackButton(this.props.counter, this.state.value);
  }



  render() {
    return (
      <div>
        <div className="textBox">
          <p>{this.props.textDescription}</p>
        </div>
        <Graph counter={this.props.counter} nodes={this.props.nodes} prevNodes = {this.props.prevNodes} links={this.props.links} foci={this.props.foci} prevFoci= {this.props.prevFoci} callBack={this.props.callBackNodes} collectHistory = {this.props.collectHistory}/>
        <div className="container" id="userInputStd">
            <p className="sliderValue">{this.state.value}</p>
            <input className="slider" type="range" min="1" max="100" value={this.state.value} onChange={this.updateSlider}/>
            <button id="confirm" onClick={this.updateCounter}>Bevestigen</button>
        </div>
      </div>
    );
  }
}
 
export default NodeSliderComponent;